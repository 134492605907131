'use client'
import Image from "next/image"
import { profile } from "../../../../../public/images"
import { Bell, ChevronLeft, Bookmark, Lightbulb, PencilRuler, Search, Settings, UserRound } from "lucide-react"
import { IoCalendarOutline } from "react-icons/io5";
import { IoReaderOutline } from "react-icons/io5";
import { TbLogout2 } from "react-icons/tb";
import { useRouter } from "next/navigation";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { logout } from "@/redux/slice/auth/authSlice";
const Dropdown = ({toggleDrawer,setOpenDropdown,setShowNotifications}) => {
    const [selected,setSelected] = useState()
    const router = useRouter()
    const dispatch = useDispatch()

    const handleLogout = ()=>{
        dispatch(logout())
        window.location.reload()
    }

    const options =[
        {
            text:'ملفي الشخصي',
        icon:<UserRound className="md:w-[15px] md:h-[15px] w-[20px] h-[20px]" />,
        onClick:() => {
            setSelected('profile')
            router.push('/dashboard/author')
            setOpenDropdown(false)
        }},
        {
        text:'مدوّنة الأفكار',
        icon:<Lightbulb  className="md:w-[15px] md:h-[15px] w-[20px] h-[20px]"/>,
        className:'md:flex hidden',
        onClick:() => {
        setSelected('profile')
        router.push('/dashboard/save-idea')
        setOpenDropdown(false)
        }},
        {
            text:'دُرج القراءة',
            icon:<Bookmark  className="md:w-[15px] md:h-[15px] w-[20px] h-[20px]"/>,
            onClick:() => {
            setSelected('profile')
            router.push('/dashboard/saved-articles')
            setOpenDropdown(false)
        },
       },
       {
        text:'الورشة',
        icon:<PencilRuler className="md:w-[15px] md:h-[15px] w-[20px] h-[20px]"/>,
        className:'flex md:hidden',
        onClick:() => {
        setSelected('profile')
        router.push('/dashboard/workshop')
        setOpenDropdown(false)
        }
       },
       {
        text:"التقويم",
        icon:<IoCalendarOutline  className="md:w-[15px] md:h-[15px] w-[20px] h-[20px]"/>,
        onClick:() => {
        setSelected('calendar')
        router.push('/dashboard')
        setOpenDropdown(false)
        }
       },
       {
        text:' صفحة الكاتب',
        icon:<IoReaderOutline  className="md:w-[15px] md:h-[15px] w-[20px] h-[20px]"/>,
        onClick:() => {
        setSelected('author-page')
        window.open('/author', '_blank', 'noopener,noreferrer');
        setOpenDropdown(false)
        }
       },
       {
        text:'إشعارات',
        icon:<Bell  className="md:w-[15px] md:h-[15px] w-[20px] h-[20px]"/>,
        onClick:() => {
        setSelected('notifications')
        setOpenDropdown(false)
        setShowNotifications(true)
        },
       },
       {
        text:'البحث',
        icon:<Search  className="md:w-[15px] md:h-[15px] w-[20px] h-[20px]"/>,
        className:'md:hidden flex',
        onClick:() => {
        setSelected('search')
        router.push('/dashboard/explore/topics')
        setOpenDropdown(false)
        }
       },
       {
        text:'الاعدادات',
        icon:<Settings  className="md:w-[15px] md:h-[15px] w-[20px] h-[20px]"/>,
        className:"md:flex hidden",
        onClick:() => {
        setSelected('settings')
        router.push('/dashboard/settings')
        setOpenDropdown(false)
        }
       }
    ]
  return (
    <div className="flex flex-col absolute md:left-2  z-50  md:border md:w-[230px] bg-white border-[#E4E4E7] md:h-fit max-h-[80vh]  md:top-10 md:gap-1 gap-2 md:rounded-md  py-4 md:py-2">
        {/*  */}
        <div className="flex gap-3 w-full justify-between items-center p-2 border-b border-[#E4E4E7]">
            <span className="flex gap-2">
                <Image src={profile} alt="profile" width={20} height={20} className="rounded-full" />
                <span className="text-[#101729] font-[500] text-[12px]"> 
                محمد الضبع
                <p className="text-[#1E2A3A] text-[9px]">Info@maldhaba.com</p>
                </span>
            </span>
            <span className="p-1 self-center text-[#8641BD] font-[500] text-[9px]  bg-[#F2ECFB] rounded-md">
            كاتب متقدم
            </span>
            <ChevronLeft onClick={toggleDrawer} size={25} className="cursor-pointer md:hidden" />
        </div>
        <div className="flex flex-col gap-4 w-full items-start h-[800px]   md:gap-2">
            {options.map((option,index)=>(
                <span key={index} onClick={option.onClick} className={`cursor-pointer hover:text-[#8641BD] hover:bg-[#F2ECFB] w-full flex gap-1 items-center p-2 text-[#101729] font-[500] text-[18px] md:text-[14px] ${option.className  && option.className}`} >
               {option.icon}
                 {option.text}
            </span>
            ))}
        </div>
        {/*  */}
        <span onClick={handleLogout} className="cursor-pointer flex gap-1 items-center p-3 text-[#E9635E] border-t border-[#e4e4e7] font-[500] text-[18px] md:text-[14px]" >
                <TbLogout2 className="md:w-[15px] md:h-[15px] w-[20px] h-[20px]" />
                تسجيل الخروج
        </span>
    </div>
  )}

export default Dropdown