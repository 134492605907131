'use client';

import { useState } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { IoClose } from 'react-icons/io5';
import Modal from 'react-modal';

import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';

import { generateIdea } from '../../../../../public/images';
import GenerateIdea from '../generate-idea/page';

const CreateIdeaModal = ({ isOpen, onClose }) => {
  const [open, setOpen] = useState(false);
  const [openSave, setOpenSave] = useState(false);
  const router = useRouter(); // Use the useRouter hook
  const [openGenerateModal, setOpenGenerateModal] = useState(false);
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      width: '90%',
      maxWidth: '600px',
      height: '500px',
      overflowY: 'auto',
      transform: 'translate(-50%, -50%)',
      scrollbarWidth: 'none',
      background: 'white',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
    },
    overlay: {
      // backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
  };

  const handleSave = (event) => {
    event.preventDefault();
    setOpenSave(true);
  };

  // Update handleClick to properly handle closing modals and routing
  const handleClick = () => {
    onClose(); // Close the main modal
    setOpenSave(false); // Close the SaveIdeaModal
    router.push('/dashboard/save-idea'); // Navigate to the desired page
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        style={customStyles}
        contentLabel="Create Idea Modal"
      >
        <div className="flex flex-col w-full h-full items-center justify-center">
          <span className="cursor-pointer absolute top-3 right-3">
            <IoClose onClick={onClose} className="h-6 w-6 cursor-pointer" />
          </span>
          <div className="flex flex-col items-center gap-5 w-full h-full">
            <p className="sm:text-[44px] text-[24px]">💡</p>
            <div className=" w-full">
              <textarea
                className="w-full max-h-52 overflow-y-auto outline-none  placeholder:text-[#BAB7B2] placeholder:text-[24px] text-right"
                placeholder="اكتب ما يخطر على بالك"
              />
            </div>
            <div className="flex gap-2 absolute bottom-20 items-center">
              <span
                onClick={() => router.push('/dashboard/generate-idea')}
                className="flex gap-1 items-center cursor-pointer bg-white border text-[#4D5861] p-2 rounded-md"
              >
                <Image
                  src={generateIdea}
                  alt=""
                  className="h-6 w-6 cursor-pointer"
                />
                الانتقال إلى المحرر ️ ✨
              </span>
              <span
                onClick={() => setOpen(!open)}
                className="flex gap-1 items-center cursor-pointer bg-[#8641BD] text-white p-2 rounded-md"
              >
                {open ? (
                  <ChevronUp className="h-4 w-4 cursor-pointer" />
                ) : (
                  <ChevronDown className="h-4 w-4 cursor-pointer" />
                )}
                حفظ الفكرة
              </span>
            </div>
        
            {open && (
              <div className="flex flex-col fixed bottom-4  p-1 mr-[-50px]  w-[150px] bg-white border  text-[12px] font-[500] text-right rounded-md">
                <span
                  onClick={handleSave}
                  className={`p-1 text-[#101729] hover:bg-[#8641BD] cursor-pointer hover:text-white rounded-md`}
                >
                  حفظ فكرة جديدة
                </span>
                <span
                  onClick={handleClick}
                  className={`p-1 text-[#101729] hover:bg-[#8641BD] cursor-pointer hover:text-white rounded-md`}
                >
                  إضافة إلى فكرة موجودة
                </span>
              </div>
            )}
          </div>
        </div>
      </Modal>
      {openSave && (
        <SaveIdeaModal
          onClose={() => setOpenSave(false)}
          handleClick={handleClick}
        />
      )}
    </>
  );
};

const SaveIdeaModal = ({ onClose, handleClick }) => {
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      width: '90%',
      maxWidth: '400px',
      maxHeight: '400px',
      overflowY: 'auto',
      transform: 'translate(-50%, -50%)',
      scrollbarWidth: 'none',
      background: 'white',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
  };

  return (
    <Modal
      isOpen={true}
      onRequestClose={onClose}
      style={customStyles}
      contentLabel="Save Idea Modal"
    >
      <div className="flex flex-col gap-2 w-full h-full items-center justify-center">
        <span className="cursor-pointer absolute top-3 right-3">
          <IoClose onClick={onClose} className="h-6 w-6 cursor-pointer" />
        </span>
        <span className="space-y-2 text-center">
          <p className="text-[32px] font-[600]">احفظ فكرتك</p>
          <p className="text-[16px] font-[400]">
            اختر اسمًا لفكرتك لتعثر عليها لاحقًا
          </p>
        </span>
        <Input />
        <Button
          onClick={handleClick}
          className="text-white w-full bg-[#8641BD]"
        >
          حفظ
        </Button>
      </div>
    </Modal>
  );
};

export default CreateIdeaModal;
