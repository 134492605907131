import { IoClose, IoReturnUpForward } from "react-icons/io5"
import { person1 } from "../../../../../public/images"
import Image from "next/image"
import { useEffect, useRef } from "react"
import Modal from 'react-modal'
const Notification = ({setShowNotifications}) => {
  const notifications = [
    {
      image:person1,
      name:'كاثرين مورفي',
      time:'منذ 18 دقيقة',
      text:'لوريم إيبسوم هو نص تجريبي يُستخدم في صناعة الطباعة والنشر. لقد تم استخدامه لفترة طويلة.'
    },
    {
      image:person1,
      name:'كاثرين مورفي',
      time:'منذ 18 دقيقة',
      text:'لوريم إيبسوم هو نص تجريبي يُستخدم في صناعة الطباعة والنشر. لقد تم استخدامه لفترة طويلة.'
    },
    {
      image:person1,
      name:'كاثرين مورفي',
      time:'منذ 18 دقيقة',
      text:'لوريم إيبسوم هو نص تجريبي يُستخدم في صناعة الطباعة والنشر. لقد تم استخدامه لفترة طويلة.'
    },
    {
      image:person1,
      name:'كاثرين مورفي',
      time:'منذ 18 دقيقة',
      text:'لوريم إيبسوم هو نص تجريبي يُستخدم في صناعة الطباعة والنشر. لقد تم استخدامه لفترة طويلة.'
    },
    {
      image:person1,
      name:'كاثرين مورفي',
      time:'منذ 18 دقيقة',
      text:'لوريم إيبسوم هو نص تجريبي يُستخدم في صناعة الطباعة والنشر. لقد تم استخدامه لفترة طويلة.'
    },
    {
      image:person1,
      name:'كاثرين مورفي',
      time:'منذ 18 دقيقة',
      text:'لوريم إيبسوم هو نص تجريبي يُستخدم في صناعة الطباعة والنشر. لقد تم استخدامه لفترة طويلة.'
    },
    {
      image:person1,
      name:'كاثرين مورفي',
      time:'منذ 18 دقيقة',
      text:'لوريم إيبسوم هو نص تجريبي يُستخدم في صناعة الطباعة والنشر. لقد تم استخدامه لفترة طويلة.'
    },
    {
      image:person1,
      name:'كاثرين مورفي',
      time:'منذ 18 دقيقة',
      text:'لوريم إيبسوم هو نص تجريبي يُستخدم في صناعة الطباعة والنشر. لقد تم استخدامه لفترة طويلة.'
    },
  ]
  const notificationRef = useRef(null)
  useEffect(()=>{
    const handleClickOutside = (event) => {
      if (
        notificationRef.current && !notificationRef.current.contains(event.target)
      ) {
        setShowNotifications(false)
      }
    }
    
    document.addEventListener('mousedown', handleClickOutside)
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  },[])
  const customStyles ={
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      width: "100%",
      maxWidth: '600px',
      height: "100%",
      overflowY: "auto",
      transform: 'translate(-50%, -50%)',
      scrollbarWidth: 'none',
      background: "white",
    },
    overlay:{
      backgroundColor: "rgba(0, 0, 0, 0)",
    }
  }
  return (
    <div className="">
      <div  ref={notificationRef} className="flex flex-col w-[300px]    border shadow-md bg-white gap-3  p-4 md:p-2 rounded-md">
        <span className="flex w-full gap-2 items-center"> 
          <IoClose onClick={() => setShowNotifications(false)} className="cursor-pointer flex " size={20} />
          {/* <IoReturnUpForward  onClick={() => setShowNotifications(false)} className="cursor-pointer md:hidden" size={20}/> */}
          <p className="md:text-[14px] text-[16px] font-[600]">إشعارات</p>
          <p className="md:text-[10px] text-[14px] text-[#555555] font-[500]">(12)</p>
        </span>
        <div className="flex  flex-col gap-2">
          {notifications.slice(0,3).map((notification,index) => (
            <div key={index} className="flex flex-col gap-2 rounded-md p-2 bg-[#F7F8F9]">
              <span className="flex  justify-between w-full items-center">
                <span className="flex gap-2 items-center">
                  <Image src={notification.image} alt="" width={30} height={30} />
                  <p className="text-[12px] font-[600] text-[#1E2A3A]">{notification.name}</p>
                </span>
                <p className="text-[10px] font-[500] text-[#71717A]">{notification.time}</p>
              </span>
              <p className="w-full text-[12px] text-black text-opacity-80 font-[400]">{notification.text}</p>
            </div>
          ))}
        </div>
        <span className="w-full  cursor-pointer hover:text-[#8641BD] text-[14px] font-[600] text-[#71717A] text-center">إظهار الكل</span>
      </div>
      {/* <Modal className="md:hidden" style={customStyles} isOpen={true} onRequestClose={()=>setShowNotifications(false)}>
      <div  ref={notificationRef} className="flex flex-col md:hidden justify-end  w-full gap-3">
        <span className="flex flex-row-reverse w-full gap-2 items-center"> 
          <IoClose onClick={() => setShowNotifications(false)} className="cursor-pointer md:flex hidden" size={20} />
          <IoReturnUpForward  onClick={() => setShowNotifications(false)} className="cursor-pointer md:hidden" size={20}/>
          <p className="md:text-[14px] text-[16px] font-[600]">إشعارات</p>
          <p className="md:text-[10px] text-[14px] text-[#555555] font-[500]">(12)</p>
        </span>
        <div style={{scrollbarWidth:"none"}} className=" overflow-y-auto flex flex-col gap-2">
          {notifications.slice(0,6).map((notification,index) => (
            <div key={index} className="flex flex-col gap-3 rounded-md p-3 bg-[#F7F8F9]">
              <span className="flex flex-row-reverse justify-between w-full items-center">
                <span className="flex flex-row-reverse gap-2 items-center">
                  <Image src={notification.image} alt="" width={40} height={40} />
                  <p className="text-[14px] font-[600] text-[#1E2A3A]">{notification.name}</p>
                </span>
                <p className="text-[12px] font-[500] text-[#71717A]">{notification.time}</p>
              </span>
              <p className="w-full text-[14px] text-right text-black text-opacity-80 font-[400]">{notification.text}</p>
            </div>
          ))}
        </div>
        <span className="w-full  cursor-pointer hover:text-[#8641BD] text-[14px] font-[600] text-[#71717A] text-center">إظهار الكل</span>
      </div>
      </Modal> */}
    </div>
  )
}

export default Notification