'use client';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import React from 'react';

const NavItem = ({ link, title, icon }) => {
  const pathname = usePathname();
  return (
    <div>
      <Link href={link} className={`link ${pathname === link ? 'text-[#8641BD]' : 'text-[#7f7f7f]'} hover:text-[#8641BD]` } >
        <div className="flex items-center justify-center gap-1 text-[16px] lg:text-[18px] font-[500] cursor-pointer text-opacity-50">
          <div>{icon}</div>
          <span>{title}</span>
        </div>
      </Link>
    </div>
  );
};

export default NavItem;
